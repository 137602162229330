<template>
    <div>
        <el-tree
            style="width: 100%;"
            :data="ZJdata"
            node-key="id"
            default-expand-all
            :expand-on-click-node="false"
            >
            <template #default="{ node, data }">
                <span class="custom-tree-node">
                <span>{{ node.label }}</span>
                <span>
                    <a style="margin-left: 8px" @click="ZJdelete(data)" class="treeBtn" v-if="data.parentId"> 删除该章节（包括所有子章节） </a>
                    <a style="margin-left: 8px" @click="ZJappend(data,'update')" class="treeBtn" v-if="data.parentId"> 修改该章节 </a>
                    <a style="margin-left: 8px" @click="ZJappend(data,'add')" class="treeBtn"> 添加子章节 </a>
                </span>
                </span>
            </template>
        </el-tree>
        <el-dialog v-model="ZJVisible_add" :title="add_or_update==='add'?'添加章节':'修改章节'" width="800" :append-to-body=true @close="ZJadd_cancel()">
            <span class="textLabel">章节名</span><el-input type="text" placeholder="章节名" v-model="chapterName"/>
            <div style="display: flex; width: 100%; justify-content: space-between;">
                <div style="width: 49%">
                    <span class="textLabel">上传章节封面{{add_or_update==='add'?'':'（不需要更改则无需上传）'}}</span>
                    <el-upload
                        class="upload-demo"
                        drag
                        @change="(e)=>uploadFile(e,'pic')"
                        :auto-upload=false
                        ref="myUpload1"
                        :limit="1"
                        :on-exceed="handleExceed1"
                        @remove="removeFile('pic')"
                    >
                        <el-icon class="el-icon--upload"><upload-filled /></el-icon>
                        <div class="el-upload__text">
                        拖拽 或 点击上传<em>图片文件</em>
                        </div>
                    </el-upload>
                </div>
                <div style="width: 49%">
                    <span class="textLabel">上传章节视频{{add_or_update==='add'?'':'（不需要更改则无需上传）'}}</span>
                    <el-upload
                        class="upload-demo"
                        drag
                        @change="(e)=>uploadFile(e,'vdo')"
                        :auto-upload=false
                        ref="myUpload2"
                        :limit="1"
                        :on-exceed="handleExceed2"
                        @remove="removeFile('vdo')"
                    >
                        <el-icon class="el-icon--upload"><upload-filled /></el-icon>
                        <div class="el-upload__text">
                        拖拽 或 点击上传<em>视频文件</em>
                        </div>
                    </el-upload>
                </div>
            </div>


            <span class="textLabel">试卷题目筛选条件设定</span>
            <div class="searchBox">
                <p>具体条件搜索</p>
                <div>
                    <el-tree-select placeholder="选填知识点" v-model="search_knowledge" multiple :data="tagNameBox" check-strictly=true :render-after-expand="false" :value-key="'id'" size="small" style="width:450px;" clearable></el-tree-select>
                    <div style="display: flex;justify-content: space-between; margin-top: 3px;">
                        <el-input
                            v-model="search_score"
                            style="width: 80px"
                            placeholder="选填分数"
                            clearable
                            size="small"
                        />
                        <el-select v-model="search_difficulty" placeholder="选填难度" style="width: 100px" size="small" clearable>
                            <el-option
                                v-for="item in box_difficulty"
                                :key="item.id"
                                :label="item.label"
                                :value="item.id"
                                clearable
                            />
                        </el-select>
                        <el-select v-model="search_questionType" placeholder="选填题型" style="width: 100px" size="small" clearable>
                            <el-option
                                v-for="item in box_type"
                                :key="item.id"
                                :label="item.label"
                                :value="item.id"
                                clearable
                            />
                        </el-select>
                        <el-select v-model="search_changed" placeholder="选填状态" style="width: 100px" size="small" clearable>
                            <el-option
                                v-for="item in box_changed"
                                :key="item.id"
                                :label="item.label"
                                :value="item.id"
                                clearable
                            />
                        </el-select>
                    </div>
                </div>
                <div class="searchBoxdiv" @click="search">设置</div>
            </div>
            <span class="textLabel">请打开下拉抽屉以选择或查看题目</span>
            <el-select v-model="topicCheck" placeholder="选择题目" :collapse-on-blur="false" class="shijuantimuxuanze">
                <template v-for="item in topicList_willshow" :key="item.id">
                    <el-option :label="item.title" :value="item.id">
                        <EditorMathText :editorText="item.title" :isPointer="false" />
                    </el-option>
                </template>
                
                <template #footer>
                <el-input
                    v-model="topicName"
                    class="option-input"
                    placeholder="输入题干关键词快捷筛选"
                    size="small"
                />
                </template>
            </el-select>
            <div style="display: flex;justify-content: right; margin-top: 15px;">
                <div @click="ZJadd_cancel()" class="buttomCancel">取消</div>
                <div @click="change()" class="buttomSubmit">{{add_or_update==='add'?'新增':'修改'}}</div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { ElMessage } from 'element-plus';
import OSS from 'ali-oss';
import { nanoid } from 'nanoid';
import axios from 'axios';
import { AXIOS_URL } from '@/constants';
import { Buffer } from 'buffer';
import EditorMathText from './EditorMathText.vue';
export default {
    components: {
        EditorMathText 
    },
    props:['ZJdata','searchZjdata','jscourseid'],
    computed:{
        topicList_willshow() {
            return this.topicList.filter(item => item.title.includes(this.topicName))
        },
    },
    data() {
        return {
            parentId: -1,
            ZJVisible_add: false,
            file_coursePic_file: '',
            file_coursePic_name: '',
            file_courseVdo_file: '',
            file_courseVdo_name: '',
            chapterName: '',
            duration: 0,

            search_changed:null,
            search_questionType:null,
            search_difficulty:null,
            search_knowledge:null,
            search_score:null,
            topicList: [],
            topicCheck: [],
            topicName: '',
            box_type:[{id:0,label:'单选题'},{id:1,label:'多选题'},{id:2,label:'判断题'}],
            box_knowledge:[],
            box_difficulty:[{id:0,label:'简单'},{id:1,label:'一般'},{id:2,label:'中等'},{id:3,label:'困难'},{id:4,label:'特困'}],
            box_changed:[{id:1,label:'可修改'},{id:0,label:'不可修改'}],

            add_or_update: '',
        };
    },
    methods: {
        useAxiosUploadpicandvdo(){
            axios.get(AXIOS_URL+'/common/oss/getTempToken')
            .then(response => {
                const client = new OSS({
                    region: 'oss-cn-hangzhou',
                    accessKeyId: response.data.data.accessKeyId,
                    accessKeySecret: response.data.data.accessKeySecret,
                    stsToken: response.data.data.securityToken,
                    bucket: 'clsp',
                    secure: true,
                })
                if(this.add_or_update==='add'){
                    try {
                        const options = {
                            meta: { temp: "demo" },
                            mime: "json",
                            headers: { "Content-Type": "application/json" },
                        };
                        // 上传文件
                        const loadingMessage = ElMessage({
                            message: '上传中...',
                            type: 'info',
                        });
                        
                        client.put(`chapter/${this.chapterName}/img/${this.file_coursePic_name}`, this.file_coursePic_file, options)
                        .then(() => {
                            client.put(`chapter/${this.chapterName}/video/${this.file_coursevdo_name}`, this.file_coursevdo_file, options)
                            .then(() => {
                                loadingMessage.close();
                                axios.post(AXIOS_URL+'/admin/course/chapter/add',{
                                    parentId: this.parentId,
                                    label: this.chapterName,
                                    duration: Math.floor(this.duration),
                                    playPath: `chapter/${this.chapterName}/video/${this.file_coursevdo_name}`,
                                    coverPath: `chapter/${this.chapterName}/img/${this.file_coursePic_name}`,
                                    questionId: this.topicCheck,
                                })
                                    .then(response => {
                                        if(response.data.code===1){
                                            ElMessage({
                                                message: '资源上传成功',
                                                type: 'success',
                                            })
                                            this.searchZjdata(this.jscourseid)
                                            this.ZJVisible_add = false
                                        }
                                    })
                                    .catch((err) => {
                                        ElMessage.error({
                                            message: `资源上传失败${err.data.msg}`,
                                        })
                                    });

                            }).catch(() => {
                                ElMessage.error({
                                    message: '视频文件上传失败',
                                })
                            });
                        }).catch(() => {
                            ElMessage.error({
                                message: '图片文件上传失败',
                            })
                        });
                    } catch (err) {
                        ElMessage.error({
                            message: '上行至储存桶发送异常',
                        })
                    }
                }
                else if(!this.file_coursePic_file&&!this.file_courseVdo_file){
                    axios.put(AXIOS_URL+'/admin/course/chapter/update',{
                        id: this.parentId,
                        label: this.chapterName,
                        duration: this.duration,
                        playPath: this.file_courseVdo_name,
                        coverPath: this.file_coursePic_name,
                        questionId: this.topicCheck,
                    })
                        .then(response => {
                            if(response.data.code===1){
                                ElMessage({
                                    message: '章节更新成功',
                                    type: 'success',
                                })
                                this.searchZjdata(this.jscourseid)
                                this.ZJVisible_add = false
                            }
                        })
                        .catch(() => {
                            ElMessage.error({
                                message: `章节更新失败`,
                            })
                        });
                }

            })
            .catch(() => {
                ElMessage.error({
                    message: 'oss凭证获取失败，请重新上传',
                })
            });
        },
        change(){
            if(!this.chapterName){
                ElMessage({
                    message: '章节名不能为空',
                    type: 'warning',
                })
                return
            }
            this.useAxiosUploadpicandvdo()
            
        },
        ZJappend(data, kind){
            this.parentId = data.id
            this.ZJVisible_add = true
            this.add_or_update = kind
            
            if(kind === 'update'){
                this.chapterName = data.label
                this.file_coursePic_name = data.coverUrl
                this.file_courseVdo_name = data.playUrl
                this.topicCheck = data.question.id
                this.duration = data.duration
            }else{
                this.chapterName = ''
                this.file_coursePic_name = ''
                this.file_coursePic_file = ''
                this.file_courseVdo_name = ''
                this.file_courseVdo_file = ''
                this.topicCheck = ''
                this.duration = 0
            }
        },
        ZJdelete(data){
            axios.delete(`${AXIOS_URL}/admin/course/chapter/delete`, {
                data: { id: data.id },
                headers: {
                    'Content-Type': 'application/json' // 可选：设置请求的内容类型
                }
            })
            .then(response => {
                // 处理成功响应
                if(response.data.code===1){
                    ElMessage({
                        message: '章节删除成功',
                        type: 'success',
                    })
                    this.searchZjdata(this.jscourseid)
                }else{
                    ElMessage.error({
                        message: response.data.msg,
                    })
                }
            })
            .catch(() => {
                ElMessage.error({
                    message: `章节删除失败`,
                })
            });
        },
        ZJadd_cancel(){
            this.ZJVisible_add = false
        },
        handleExceed1(files){
            let point = files[0]
            this.$refs['myUpload1'].clearFiles()
            this.$refs['myUpload1'].handleStart(point)
        },
        handleExceed2(files){
            let point = files[0]
            this.$refs['myUpload2'].clearFiles()
            this.$refs['myUpload2'].handleStart(point)
        },
        removeFile(kind){
            if(kind==='pic'){
                this.file_coursePic_file = ''
                this.file_coursePic_name = ''
            }else{
                this.file_coursevdo_file = ''
                this.file_coursevdo_name = ''
            }
        },
        uploadFile(e,kind){
            if(kind==='pic'){
                if(!e.raw.type.includes('image')||e.raw.type.includes('gif')){
                    ElMessage({
                        message: '请上传非GIF的图片文件',
                        type: 'warning',
                    })
                    this.$refs['myUpload1'].clearFiles();
                    this.file_coursePic_file = ''
                    this.file_coursePic_name = ''
                }else{
                    this.file_coursePic_name = nanoid()+'.'+e.name.split('.').pop();
                    this.file_coursePic_file = e.raw;
                }
            }else{
                if (!e.raw.type.includes('video')) {
                    ElMessage({
                        message: '请上传视频文件',
                        type: 'warning',
                    });
                    this.$refs['myUpload2'].clearFiles();
                    this.file_coursevdo_file = '';
                    this.file_coursevdo_name = '';
                } else {
                    this.file_coursevdo_name = nanoid() + '.' + e.name.split('.').pop();
                    // this.file_coursevdo_file = e.row;
                    const reader = new FileReader();

                    reader.onloadend = () => {
                        const arrayBuffer = reader.result; // ArrayBuffer 已经准备好
                        const buffer = Buffer.from(new Uint8Array(arrayBuffer)); // 将 ArrayBuffer 转换为 Buffer
                        this.file_coursevdo_file = buffer
                    };

                    reader.readAsArrayBuffer(e.raw);

                    
                    // 创建一个URL对象用于加载视频
                    const videoElement = document.createElement('video');
                    videoElement.src = URL.createObjectURL(e.raw);

                    // 监听视频元数据加载完成事件
                    videoElement.addEventListener('loadedmetadata', () => {
                        this.duration = videoElement.duration; // 获取视频时长（单位：秒）
                        // 释放URL对象
                        URL.revokeObjectURL(videoElement.src);
                    });
                }
            }
        },
        search(){
            this.getTopicList(null,this.search_score===''?null:this.search_score,this.search_difficulty===''?null:this.search_difficulty,this.search_questionType===''?null:this.search_questionType,this.search_knowledge.length===0?null:this.search_knowledge,this.search_changed===''?null:this.search_changed)
        },

        getTopicList(title,score,difficulty,questionType,knowledge,changed){
            // console.log(title,score,difficulty,questionType,knowledge,changed);
            axios.post(AXIOS_URL+'/admin/question/pageSearch',{page:1,size:10,title,score,difficulty,questionType,knowledge,changed})
            .then(response => {
                this.topicList = response.data.data.records
                var unum = 2
                while(unum<=response.data.data.pages){
                    axios.post(AXIOS_URL+'/admin/question/pageSearch',{page:unum,size:10,title,score,difficulty,questionType,knowledge,changed})
                    .then(response => {
                        this.topicList = response.data.data.records.concat(this.topicList)
                    })
                    unum+=1
                }
                ElMessage({
                    message: '题目列表获取成功',
                    type: 'success',
                })
            })
            .catch(() => {
                // 处理错误
                ElMessage.error({
                    message: '获取题目列表失败',
                })
            });
        },
        
        getTagName(){
            axios.get(AXIOS_URL+'/admin/selectBox/knowledgeTree')
            .then(response => {
                this.tagNameBox = response.data.data
            })
            .catch(() => {
                // 处理错误
                ElMessage.error({
                    message: '知识点信息拉取失败',
                })
            });
        },
    },
    mounted() {
        this.getTagName()
        this.getTopicList(null,null,null,null,null,null)
    }
};
</script>


<style scoped>
.textLabel{
    margin-top: 15px;
}
.cardBox{
    width: 100%;
    margin-top: 10px;
    box-shadow: #eeeff1 0 0 70px 15px;
    border-radius: 10px;
    background-color: #ffffff;
    box-sizing: border-box;
    padding: 15px;
    min-height: 101vh;
}
.messageBox{
    margin: 25px 60px;
}
.messageBox h3{
    padding-bottom: 20px;
    border-bottom: solid 1px #14141444;
    margin-bottom: 35px;
}
.paperName{
    margin: 3px 0 5px 6px;
    font-size: 16px;
}
.el-transfer-panel{
    width: 265px !important;
}

.searchBox{
    margin: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    width: 90%;
    margin-bottom: 5px;
    box-sizing: border-box;
    overflow: hidden;
    border: #e72b47 1px solid;
    border-radius: 30px;
    background-color: #ffecec;
}
.searchBox p{
    font-size: 13px;
    color: #e72b47;
    font-weight: 600;
    padding-left: 20px;
}
.searchBoxdiv{
    background-color: #e72b47;
    height: 60px;
    width: 55px;
    text-align: center;
    line-height: 60px;
    font-size: 13px;
    color: #ffecec;
    cursor: pointer;
}
</style>


<style scoped>
.treeBtn{
    font-size: 12px;
    padding: 2px 11px;
    transition: .3s ease;
    border: 1px #dcdfe6 solid;
    background-color: transparent;
    color: #606266;
    border-radius: 2px;
}
.treeBtn:hover{
    border-color: #ffc6c6;
    background-color: #ffecec;
    color: #eb2b47;
}
.cardBox{
    width: 100%;
    margin-top: 10px;
    box-shadow: #eeeff1 0 0 70px 15px;
    border-radius: 10px;
    background-color: #ffffff;
    box-sizing: border-box;
    padding: 15px;
    min-height: 101vh;
}
.messageBox{
    margin: 25px 60px;
}
.messageBox h3{
    padding-bottom: 20px;
    border-bottom: solid 1px #14141444;
    margin-bottom: 35px;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
</style>